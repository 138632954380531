html {
  font-size: 62.5%;
}

body {
  font-size: 1.6rem;
}

*, *::before, *::after {
  box-sizing: border-box;
}

a {
  text-decoration: none;
  color: inherit;
  cursor: pointer;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
}

p {
  margin: 0;
}

cite {
  font-style: normal;
}

fieldset {
  border-width: 0;
  padding: 0;
  margin: 0;
}
img{
  width: 100%;
}

ul{
  list-style: none;
  padding: 0;
  margin: 0;
}
