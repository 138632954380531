@font-face {
  font-family: 'Circular Std Light';
  src: url('../fonts/CircularSpotifyText-Light.woff2') format('woff2'),
  url('../fonts/CircularSpotifyText-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

:root {
  --font-light: 'Circular Std Light', sans-serif;
}

body {
  font-family: var(--font-light);
  color: var(--black);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 2rem;
  font-weight: 300;
  line-height: 1.25;
  @media only screen and (min-width: $Tablet) {
    font-size: 1.9rem;
  }
}
