figure {
  margin: 0;
}

/* aspect ratio */
.ima {
  display: block;
  width: 100%;
  position: relative;
  overflow: hidden;

  img {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
}

.f16x9 {
  padding-top: 56.25%;
}
