html {
  width: 100%;
  height: 100%;
}

body {
  height: 100%;
  width: 100%;
  padding: 2.2rem 2.5rem 2.2rem;
  @media only screen and (min-width: $screenMd) {
    padding: 3.2rem 2.8rem 2.8rem;
  }

  .header {
    display: flex;
    flex-direction: column;
    @media only screen and (min-width: $screenMd) {
      flex-direction: row;
      justify-content: space-between;
    }

    &__brand {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      @media only screen and (min-width: $screenMd) {
        justify-content: space-between;
        flex-direction: row;
        width: 30%;
      }

      &--tagline {
        margin-bottom: 3.5rem;
        font-size: 2rem;
        font-weight: 300;
        @media only screen and (min-width: $screenMd) {
          font-size: 1.9rem;
          margin-bottom: 0;
        }
      }

      &--name {
        font-size: 2rem;
        font-weight: 300;
        @media only screen and (min-width: $screenMd) {
          font-size: 1.9rem;
          margin-bottom: 0;
          position: absolute;
          left: 10%;
        }
      }
    }

    &__nav {
      width: 100%;
      @media only screen and (min-width: $screenMd) {
        width: 35%;
      }
    }

    &__menu {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      @media only screen and (min-width: $screenMd) {
        flex-direction: row;
        width: 100%;
        justify-content: flex-end;
      }

      &--item{
        &.item-i2{
          @media only screen and (min-width: $screenMd) {
            position: absolute;
            left: 10%;
          }
        }
        &.item-x{
          @media only screen and (min-width: $screenMd) {
            position: absolute;
            left: 20%;
          }
        }
      }

      &--link {
        font-size: 1.9rem;
        font-weight: 300;
        color: var(--grey);
        @media only screen and (min-width: $screenMd) {
          font-size: 2rem;
        }

        &:hover {
          color: var(--black);
        }
      }

    }
  }

  main {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-end;

    .logo {
      position: absolute;
      bottom: 2rem;
      width: calc(100% - 4.4rem);
      height: auto;
      @media only screen and (min-width: $screenMd) {
        width: calc(100% - 3.8rem);
      }
    }
  }
}
